import { useState } from "react";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { getPocoDoctorsFun, gethDoctorsRosterFun, sendMedicalPerk, sendMeetingLinkFun, sendWhatsappMessage, updateTicket } from "../../pages/actions/actions";
import { createWhatsappMessagePayload, currentDateTime } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { uploadFile } from "../../pages/actions/uploadFiles";
import { useEmergencyContact } from "../useEmergencyContact/UseEmergencyContact";
import { useModal } from "../../components/organisms/modalProvider/ModalProvider";
import { PaymentLink } from "../../components/molecules/paymentLink/PaymentLink";
import config from "../../config/config";
interface doctorRosterInterface {
  doctorId: string,
  doctorName: string,
  mobileNo: string,
  endTime: string,
  startTime: string,
  languages?: [],
  pocoDoctor?: boolean,
};
interface mobileNumbersInterface {
  number: string,
  role: "patient" | "doctor"
}

interface MeetingPayloadInterface {
  beneficiaryId: string;
  uploadPrescriptionUrl: string;
  medicalInfoUrl: string;
  mobileNumbers?: mobileNumbersInterface[];
};

export const useDoctorConsult = () => {
  const dispatch=useDispatch()
  const [uploading, setUploading] = useState(false);
  let token = localStorage.getItem("token");  
  const { getTicketData ,getTicketStatus } = UseGetStoreData();
  const {showModal}=useModal();
  // console.log('getTicketData:', getTicketData);
  // let doctorPayload = {
  //   ...(getTicketData?.doctorInfo?.fullName ? { name: getTicketData?.doctorInfo?.fullName, number: getTicketData?.doctorInfo?.mobile } : {}),
  // };
  // console.log('doctorPayload:', doctorPayload);
  const [doctorData, setDoctorData] = useState<doctorRosterInterface | null>(null);
  const [doctorName, setDoctorName] = useState("");
  const [doctorNumber, setDoctorNumber] = useState("");
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  //eslint-disable-next-line 
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prescriptionDocUrl, setPrescriptionDocUrl] = useState(
    getTicketData?.doctorInfo?.clinicalNotes[0]?.url || ""
  );
  const { validContacts } = useEmergencyContact()
  const [doctorRosters, setDoctorRoster] = useState<doctorRosterInterface[] | []>([])
  const [inputPatientMobiles, setInputPatientMobiles] = useState<string[] | []>([])
  const [inputDoctorMobiles, setInputDoctorMobiles] = useState<string[] | []>([])
  const [sendMeetingLoading, setSendMeetingLoading] = useState<boolean>(false);
  // const [inputValue, setInputValue] = useState<string>('');
  // console.log('getTicketData:', getTicketData);
  // console.log('isDoctorSelected:', isDoctorSelected);
  const handleDoctorChange = (doctorId: string) => {
    const doctorData = doctorRosters.filter((el) => el.doctorId === doctorId);
    setDoctorData(doctorData[0]);
  };
 
  const handleDoctorPhoneNumber = (phNumber: string) => {
    const formattedPhNum = phNumber.replace(/\D/g, "").slice(0, 10);
    // console.log('formattedPhNum:', formattedPhNum);
    setDoctorNumber(formattedPhNum);
  };
  const handleShareProfile = async () => {
    if (!doctorName || !doctorNumber || doctorNumber.length < 10 || doctorNumber.length > 10  ) {
      return ShowAlert("warning", "Please Enter Doctor Details");
    }
    setBtnIsLoading(true);
    const getDoctorUrl = await getPatientProfile();
    const TicketUpdateTracking = {
      notes: [...getTicketData?.notes, {
        date: currentDateTime(),
        message: "Patient Profile Shared"
      }]
    };
    const ticketPayload = {
      doctorInfo: {
        fullName: doctorName,
        mobile: doctorNumber,
      },
      ...TicketUpdateTracking
    };
    const waPayload = {
      to: [doctorNumber],
      doctorName: doctorName,
      beneficiary: getTicketData?.ticketRaisedBy?.fullName ||getTicketData?.subscriber?.fullName|| "N/A",
      patient: getTicketData?.patient?.fullName, //from
      beneficiaryAge: getTicketData?.patient?.age || "N/A",
      beneficiarySex: getTicketData?.patient?.sex || "N/A",
      isBeneficiaryConcious:
        getTicketData?.currentMedicalStatusResponse?.isPatientConscious||"N/A",
      patientCondition: `${
        getTicketData?.currentMedicalStatusResponse?.currentCondition?.join(
          ","
        ) || "N/A"
      } Others: ${
        getTicketData?.currentMedicalStatusResponse?.otherDescription?.trim() ||
        "N/A"
      }`,
      isAmbulanceRequested:
        getTicketData?.currentMedicalStatusResponse?.isAmbulanceRequired, //fromData
      poc: getTicketData?.poc?.fullName||"N/A", //fromData
      pocNumber: getTicketData?.poc?.mobile||"N/A", //fromData
      url: getDoctorUrl?.signedUrl
        ? getDoctorUrl?.signedUrl
        : "https://developmentbucket.blob.core.windows.net/pdfs/beneficiary-profile-not-getting-error-default-pdf.pdf",
    };
    const shareEmTemplate = createWhatsappMessagePayload({
      templateName: "NOTIFY_DOCTOR_ABOUT_PATIENT",
      data: waPayload,
    });
    const res=await updateTicket(getTicketData._id,ticketPayload);
    if (res){
      // console.log('res:', res);
      dispatch(ticketDataSuccess(res.data))
      sendWhatsAppMessageToEm(shareEmTemplate,"Patient details shared to doctor");
    }
  };
  const getPatientProfile = async () => {
    if (getTicketData?.patient?.id) {
      return await doctorPerk(getTicketData?.patient?.id);
    }else{
      ShowAlert("warning", "Selected Ticket has no Patient");
    }
    
  
  };
  const sendWhatsAppMessageToEm = async (payload: any,message:string) => {
    try {
      // setBtnIsLoading(true);
      const response = await sendWhatsappMessage(payload);
      if (response) {
        setIsLoading(false);
        setBtnIsLoading(false);
        ShowAlert("success",message)
      }
    } catch (e: any) {
      setIsLoading(false);
      setBtnIsLoading(false);
      alert(e?.response?.data?.message || "Some Technical Issue sending whatsapp msg");
    }
  };
  const doctorPerk = async (benId: string) => {
    try {
      const res = await sendMedicalPerk(benId);
      console.log(res+
        'from ben Document'
      )
      if (res.signedUrl) {       
        return res;
      } else {        
        alert("Some Technical issue in getting Doctor Perk");
      }
    } catch (e: any) {
      console.log("error from ", e);    
      setBtnIsLoading(false);
    }
  };

  const handleUpdatePrescriptionUrl = async (prescriptionUrl: string) => {
    const value = { url: prescriptionUrl, type: "img", tags: ["check"] };
    const ticketData = {
       doctorInfo: {
        ...getTicketData.doctorInfo,
        clinicalNotes: [...getTicketData?.doctorInfo?.clinicalNotes, value],
      },
      
    };
   
    const response:any=await updateTicket(getTicketData._id, ticketData);
    dispatch(ticketDataSuccess(response.data));
  };

  const handlePrescriptionDoc = async (files: any) => {
    setUploading(true);
    setPrescriptionDocUrl("uploading");
    try {
      let res = await uploadFile(
        getTicketData?.patient?.id || getTicketData?.ticketRaisedBy?.benId,
        files,
        "doctorPrescription"
      );
      
      setUploading(false);
      localStorage.setItem("token",res.token)
       await handleUpdatePrescriptionUrl(res.url);
      const baseUri = `${config.api.baseURL}/azure/api/v1/signedUrl/get-pdf/.pdf`;
      setPrescriptionDocUrl(baseUri);
    } catch (error) {
      console.error(error);
      setUploading(false);
      setPrescriptionDocUrl("");
      alert("Some Technical Error from uploading PDF");
    }
  };

  const handleViewCurrentDocument = () => {
    console.log()
    window.open(
      prescriptionDocUrl+"?token="+token || getTicketData?.doctorInfo?.clinicalNotes[0]?.url+"?token="+token ,
      "_blank"
    );
  };

  const handlePrescriptionSend = async () => {
    setBtnIsLoading(true);

    const WAPayloadBen = {
      template: "NOTIFY_EC_ON_DOCTOR_CONSULT",
      data: {
        to: [...validContacts],
        beneficiary: getTicketData?.patient?.fullName || "N/A",
      },
    };

    let waMessagePayload = {
      template: "NOTIFY_PATIENT_ON_PRESCRIPTION",
      data: {
        to: [
         ...validContacts
        ],
        url: prescriptionDocUrl+"?token="+token,
      },
    };
    const payload={
      notes: [...getTicketData?.notes, {
        date: currentDateTime(),
        message: "Doctor prescription shared to Patient"
      }]
    }
    await updateTicket(getTicketData._id,payload,dispatch);
    sendWhatsAppMessageToEm(WAPayloadBen,"Doctor Consultation Notification Shared to Ec");
    sendWhatsAppMessageToEm(waMessagePayload,"Doctor prescription shared to Patient");
  };


  const HandleDoctorRoasters = async () => {
    let roster = await gethDoctorsRosterFun()
    let pocoDoctors: [] | object[] = await getPocoDoctorsFun()
    let hospitalContacts = getTicketData?.hospitalInfo?.hospitalContacts?.filter((item: any) => item.contact?.length > 0 && item.designation.toLowerCase() === "doctor")
    let emergencyDoctorContacts: any = []

    hospitalContacts?.forEach((item: any) => {
      let number = null
      for (let i = 0; i < item?.contact?.length; i++) {
        if (item?.contact[i]?.length === 10 && !isNaN(item?.contact[i])) {
          number = item.contact[i]
          break
        }
      }
      if (number) {
        emergencyDoctorContacts.push({
          doctorId: item.doctorId || Math.random.toString(),
          doctorName: item.name,
          mobileNo: number,
          endTime: item.endTime,
          startTime: item.startTime,
        })
      }
    })

    let pocoDoctorsArray = pocoDoctors?.length > 0 ? pocoDoctors.map((doctor: any) => {
      return {
        doctorId: doctor.doctorId,
        doctorName: doctor.name,
        mobileNo: doctor.mobileNo,
        endTime: doctor.timeSlots.endTime,
        startTime: doctor.timeSlots.startTime,
        pocoDoctor: true
      }
    }) : []
    let combinedRosters: any = []
    if (roster) {
      combinedRosters.push(...roster)
    }
    if (emergencyDoctorContacts) {
      combinedRosters.push(...emergencyDoctorContacts)
    }
    if (pocoDoctorsArray) {
      combinedRosters.push(...pocoDoctorsArray)
    }
    setDoctorRoster(combinedRosters)
  }

  const handlePatientMobileNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let number = e.target.value.trim().split(",")
    let trimmedNumbers = number.map((item) => item.trim())
    let numberArr = trimmedNumbers.filter((item) => item !== '')
    setInputPatientMobiles(numberArr.length > 0 ? numberArr : [])
  }

  const handleDoctorMobileNo = (e: React.ChangeEvent<HTMLInputElement>) => {

    let number = e.target.value.trim().split(",");
    // console.log('number:', number);
    // let trimmedNumbers = number.map((item) => item.trim()).filter((item) => /^[0-9]{10}$/.test(item));
    // console.log('trimmedNumbers:', trimmedNumbers);
    // let numberArr = trimmedNumbers.filter((item) => item !== '')
    let numberArr = number.map((item) => {
      // Extract only the first 10 digits
      let trimmed = item.trim().slice(0, 10);
      return /^[0-9]{10}$/.test(trimmed) ? trimmed : '';
    }).filter((item) => item !== ''); // Filter out empty strings
    console.log('numberArr:', numberArr);    
    // setInputValue(numberArr.join(','));
    setInputDoctorMobiles(numberArr.length > 0 ? numberArr : []);
  }

  const sendMeetingLink = async () => {
    setSendMeetingLoading(true)
    if (!doctorData && inputDoctorMobiles.length <= 0) {
      alert("Please select doctor or add custom doctor numbers")
      setSendMeetingLoading(false)
      return
    }

    let isNumberInvalid = false

    if (inputDoctorMobiles.length > 0) {
      inputDoctorMobiles.forEach((item) => {
        if (isNumberInvalid) {
          return
        }
        if (!Number(item) || item.length !== 10) {
          isNumberInvalid = true
          alert("Please Provide Valid Doctor Numbers")
          setSendMeetingLoading(false)
          return
        }
      })
    }

    if (inputPatientMobiles.length > 0) {
      inputPatientMobiles.forEach((item) => {
        if (isNumberInvalid) {
          return
        }
        if (!Number(item) || item.length !== 10) {
          isNumberInvalid = true
          alert("Please Provide Valid Patient Numbers")
          setSendMeetingLoading(false)
          return
        }
      })
    }

    if (isNumberInvalid) {
      setSendMeetingLoading(false)
      return
    }
    let payload: MeetingPayloadInterface = {
      beneficiaryId: getTicketData?.patient?.id,
      uploadPrescriptionUrl: "https://dev.apis.pococare.com/azure/api/v1/signedUrl/generateSignedUrl",
      medicalInfoUrl: `https://dev.apis.pococare.com/api/v1/beneficiary/medical-info-perk/${getTicketData?.patient?.id}`,
    }

    let mobileNumbers: mobileNumbersInterface[] = []
    if (getTicketData?.patient?.mobile) {
      mobileNumbers.push({ number: getTicketData?.patient?.mobile, role: "patient" })
    }
    if (getTicketData?.ticketRaisedBy?.mobile) {
      mobileNumbers.push({ number: getTicketData?.ticketRaisedBy?.mobile, role: "patient" })
    }
    if (doctorData) {
      mobileNumbers.push({ number: doctorData?.mobileNo, role: "doctor" })
    }
    if (inputPatientMobiles?.length > 0) {
      let patientMobileArr: mobileNumbersInterface[] = inputPatientMobiles.map((number) => { return { number: number, role: "patient" } })
      mobileNumbers.push(...patientMobileArr)
    }
    if (inputDoctorMobiles?.length > 0) {
      let doctorMobileArr: mobileNumbersInterface[] = inputDoctorMobiles.map((number) => { return { number: number, role: "doctor" } })
      mobileNumbers.push(...doctorMobileArr)
    }

    payload["mobileNumbers"] = mobileNumbers
    // eslint-disable-next-line 
    let response = await sendMeetingLinkFun(payload)
    setSendMeetingLoading(false)
    if (response) {
      const payload={
        notes: [...getTicketData?.notes, {
          date: currentDateTime(),
          message: "Meeting link shared to patient"
        }]
      }
      await updateTicket(getTicketData._id,payload,dispatch)
      alert("MeetingLink Sent Successfully")
    }
  }

  //send-payment-link functions
  const sendDoctorConsultPaymentLink =  () => {
    showModal(<PaymentLink component="doctor-consultation"/>,"Doctor-Consultation")    
  }

  return {
    handleDoctorChange,   
    doctorData,
    doctorName,
    setDoctorName,
    doctorNumber,
    handleDoctorPhoneNumber,
    handleShareProfile,   
    isBtnLoading,
    getTicketData,
    prescriptionDocUrl,
    handlePrescriptionDoc,
    uploading,
    handleViewCurrentDocument,
    handlePrescriptionSend,
    HandleDoctorRoasters,
    doctorRosters,
    handlePatientMobileNo,
    handleDoctorMobileNo,
    inputDoctorMobiles,
    inputPatientMobiles,
    sendMeetingLink,
    setSendMeetingLoading,
    sendMeetingLoading,
    sendDoctorConsultPaymentLink,
    getTicketStatus
  };
};
