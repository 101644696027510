
import { Button, Row, Col } from "antd";
import apiConfig from "../../../config/config";

const baseUri = apiConfig.api.baseURL;

const MicrosoftLogin = () => {
  let url = `${baseUri}/auth/api/v1/auth/admin/microsoft`;
  const handleMicrosoftLogin = () => {
    window.location.href = url;
    console.log(window.location.href)
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        type="primary"
        style={{ backgroundColor: 'rgb(213,63,140)', color: 'white', width: '100%' }}
        onClick={handleMicrosoftLogin}
      >
        <Row justify="center" align="middle">
          <Col>
            <span>Login with Microsoft</span>
          </Col>
          <Col>
            <img
              src="/images/Microsoft_logo.png"
              alt="Microsoft Logo"
              style={{ width: '24px', marginLeft: '5px' }}
            />
          </Col>
        </Row>
      </Button>
    </div>
  );
};

export default MicrosoftLogin;
