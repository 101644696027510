import { Container } from "../../atoms/container/Container";
import { RadioButton } from "../../atoms/singleRadioButton/RadioButton";
import style from "./addressCard.module.scss";
import { Address } from "../address/Address";
import { useGetHospitalDist } from "../../../hooks/useGetHospitalDistance/useGetHospitalDist";

interface AddressCardProps {
  address: any;
  handleAddressSelection: any;
  selectedAddress: any;
  leftSideIcon:string;
  rightSideBadgeText:string;
  pickupLocation?:any
  count?:any
  handleEmergency?:string|null
}
export const AddressCard: React.FC<AddressCardProps> = ({
  count,
  address,
  handleAddressSelection,
  selectedAddress,
  leftSideIcon,
  rightSideBadgeText,
  pickupLocation,
  handleEmergency
}) => {
  // console.log('address:', address)
  // console.log('pickupLocation:', pickupLocation);
  const {km}=useGetHospitalDist({pickupLocation,address,count})
console.log('  address:',   address);
     
  
  return (
    <>
      <Container>
        <div className={style.card_flex}>
          <div className={style.radio_btn}>
            <RadioButton
              id={address?._id}
              name="addressSelection"
              isSelected={address === selectedAddress}
              onChange={() => handleAddressSelection(address)}
              className={style.radio}
            />
          </div>
          <Address
           onChange={() => handleAddressSelection(address)}
            leftSideIcon={leftSideIcon}
            rightSideBadgeText={handleEmergency?handleEmergency:rightSideBadgeText}
            className={
              address === selectedAddress ? style.card_border : style.card
            }
            line1={address?.label||address?.name ||""}
            line2={address?.addressLine1 ||address?.address|| ""}
            line3={rightSideBadgeText!=="Partnered"?`${address?.addressLine2 || ""}, ${address?.city || ""}`:""}
            line4={rightSideBadgeText!=="Partnered"?`${address?.state || ""},${address?.pinCode || ""}`:`kilometers:${km}`}
          />
        </div>
      </Container>
    </>
  );
};
