
import { uploadFileHttp } from "../../http";
export const uploadFile = async (beneficiaryId: string, files: any, fileType: string) => {
  
  try {
    let uploadToAzureResponse = await uploadFileToAzure(files.fileList[0].name,beneficiaryId,fileType, files);
    return uploadToAzureResponse;
  } catch (error) {
    throw error;
  }
}

const uploadFileToAzure = async (docName: string, benId: string, docType: string, files: any) => {
  try {
   
    const base64String = files.base64; 
    const contentType = base64String.split(",")[0].split(":")[1].split(";")[0]; 
    const file = base64ToFile(base64String, docName, contentType);

    const formData = new FormData();
    formData.append("file", file); 
    formData.append("benId", benId);
    formData.append("docType", docType);
    formData.append("docName", docName);
    let res = await uploadFileHttp(formData);
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};



const base64ToFile = (base64String: string, fileName: string, contentType: string) => {
  const byteCharacters = atob(base64String.split(",")[1]); // Decode base64
  const byteArrays = [];
  
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: contentType });
  return new File([blob], fileName, { type: contentType });
};