import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import React from "react";
import { persister, store } from "./redux/store/store";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ErrorBoundary } from "./errorBoundary/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient()
// console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
if (process.env.NODE_ENV !== "development" && window.location.hostname !== "localhost") {
  console.log = function () {}; 
  console.warn = function () {}; 
  console.error = function () {}; 
};

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>
  </ErrorBoundary>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();