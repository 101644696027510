// @ts-ignore
import { useEffect, useState } from 'react';

export const InternatStatus = ({ children }: any) => {

  const [isOnline, setIsOnline] = useState(true);
  // console.log("Initial Status:", navigator.onLine ? "Online" : "Offline");


  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
 
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return <>
    {
      isOnline ? <>{ children }</> : <div style={{ textAlign: 'center', backgroundColor: '#ffcc00', color: '#333', padding: '20px' }}>
        <h2>No Internet Connection</h2>
        <p>Please check your network and try again.</p>
      </div>
    }
  </>
}